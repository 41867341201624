<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="基础合同编号">
              <el-input v-model="searchForm.contracNo" placeholder="请输入要搜索的基础合同编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="买方名称">
              <el-input v-model="searchForm.buyFirmName" placeholder="请输入要搜索的买方名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="卖方名称">
              <el-input v-model="searchForm.sellFirmName" placeholder="请输入要搜索的卖方名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.back1" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.assetsContractStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="80">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'assetsContractDetail', query: { contractId: scope.row.id } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        contracNo: '',
        buyFirmName: '',
        sellFirmName: '',
        back1: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      uploadDialog: {
        visible: false,
        title: ''
      },
      columnOption: [
        { label: '基础合同编号', prop: 'contracNo', formatter: this.utils.isEffective, minWidth: 120 },
        { label: '合同名称', prop: 'contracName', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '买方名称', prop: 'buyFirmName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '卖方名称', prop: 'sellFirmName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'usageStatus', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'assetsContractStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.assets.contract.listPage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style>

</style>
